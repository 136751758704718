import { useState } from "react";
import { APP_URI } from "../constants";
import { BrokerOptions, IBroker, IBrokerOptions } from "./broker/Broker";
import Modal from "./modal/Modal";

const ModalContent = ({data}: {data: IBroker}) => {
  return (
    <div className="school-content">
      <h2 className="school-content__title">О БРОКЕРЕ: {data.name}</h2>
      <p>{data.description}</p>
    </div>
  )
}

export default function Recommendations() {
  const [selected, setSelected] = useState<IBroker|null>(null)
  const data: IBrokerOptions = {
    min_dep: '5$',
    min_bet: '1$',
    bonus: '40-150%',
    amount: 'от 20$',
    reg_rf: 1,
    description: `PocketOption - это инновационная платформа для тех, кто хочет получить максимальную прибыль от торговли бинарными опционами. Это лучший выбор для тех, кто ищет надежного брокера с превосходными возможностями и отличной поддержкой клиентов.

    Вот только некоторые из многих преимуществ, которые предлагает PocketOption:
    
    Простота использования - интерфейс легок в использовании и понимании, даже для начинающих трейдеров.
    
    Большой выбор активов - у нас есть более 100 активов для торговли, включая валютные пары, криптовалюты, индексы и многое другое.
    
    Высокие выплаты - мы предлагаем до 96% выплаты на торговые сделки, что является одним из лучших показателей в отрасли.
    
    Быстрый вывод средств - мы гарантируем быстрый и безопасный вывод средств в любое время.
    
    Бонусы и акции - мы предлагаем регулярные бонусы и акции для наших клиентов, чтобы помочь им увеличить свою прибыль.
    
    Круглосуточная поддержка - наша команда поддержки клиентов работает 24/7, чтобы помочь вам в любое время.
    
    Кроме того, мы предлагаем широкий спектр обучающих материалов и инструментов для анализа рынка, чтобы помочь нашим клиентам принимать взвешенные решения во время торговли.
    
    PocketOption - это идеальный выбор для тех, кто ищет надежного брокера, который предлагает лучшие условия для торговли бинарными опционами. Присоединяйтесь к нам сегодня и начните получать максимальную прибыль от торговли!`,
    name: 'POCKETOPTION',
    image: "broker-recommend.png",
    id: 555,
    link: 'https://po5.cash/register?utm_source=affiliate&a=7JJV7zfhF31zjy&ac=fxleader&code=100BONUS'
  }
  return (
    <div className="container">
    <div className="recommend mb50">
      <div className="tac mb30">
        <h2 className="title2">мы рекомендуем:</h2>
      </div>
      
      <BrokerOptions isCard onClick={()=>setSelected(data)} data={data} />

      <Modal isOpen={selected!==null} close={()=>setSelected(null)}>
        {selected ? <ModalContent data={selected}/> : null}
      </Modal>
      </div>
    </div>
  )
}