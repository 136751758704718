import React, { ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom'
import ReactFocusLock from 'react-focus-lock'
import './modal.css'

interface ModalProps {
  title?: string
  drawer?: boolean
  children: React.ReactNode
  close(): void
}

let $appModal = document.getElementById('app-modal') as HTMLElement

if (!$appModal) {
  $appModal = document.createElement('div')
  $appModal.id = 'app-modal'
  document.body.appendChild($appModal)
}

function ModalC({children, title, drawer, close}: ModalProps) {
  return (
    <>
      <ReactFocusLock>
        <div aria-modal aria-labelledby={title} tabIndex={-1} role="dialog" className={drawer ? 'drawer' : "modal"}>
          <button className="backdrop" type="button" aria-label="Close" onClick={close}>close</button>
          <div className="modal-container">
            <div className="modal__close" onClick={close}><div className="cross"></div></div>
            <div className="modal-content">{children}</div>
          </div>
        </div>
      </ReactFocusLock>
    </>
  )
}

interface IModal extends ModalProps {
  isOpen: boolean
}
type IModalType = React.FC<IModal> & {
  Header: React.FC<{
    title: string
    extra?: ReactNode
  }>
}
const Modal: IModalType = ({isOpen, close, children, title, drawer}) => {
  
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isOpen) {
      close()
    }
  }
  
  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset')
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
      $appModal?.childElementCount === 0 && (document.body.style.overflow = 'unset')
    }
  }, [isOpen])


  return isOpen ? ReactDOM.createPortal(
    <ModalC close={close} children={children} title={title} drawer={drawer}/>, $appModal
    ) : null;
}


Modal.Header = ({title, extra}) => <div className="modal-head">
    <div className="modal-title">{title}</div>
    <div className="modal-extra">{extra}</div>
  </div>
export default Modal