import { ReactNode } from 'react';
import { APP_URI } from '../../constants';
import './broker.css';

export interface IBroker {
  id: number;
  name: string;
  description: string;
  link: string;
  image: string;
  min_dep: string;
  amount: string;
}

export interface IBrokerOptions extends IBroker {
  min_bet: string;
  bonus: string;
  reg_rf: 1|0;
}

export interface IBrokerCrypto extends IBroker {
  fee: string;
  traffic: string;
  currencies: string;
}

export interface IBrokerForex extends IBroker {
  ratio: string;
  spread: string;
  founded: string;
}


const isMobile = window.innerWidth < 992


type cellsType<IB extends IBroker> = {name: string, key: keyof IB, className?:string, render?: (value: any)=>ReactNode}[]
type BrokerProps<IB> = {onClick: ()=>void, data: IB, isCard?: boolean}


const Broker = <IB extends IBroker>({onClick, data, cells, isCard}:BrokerProps<IB> & { cells: cellsType<IB>}) => {
  const className = isCard ? 'broker-card' : (isMobile ? 'broker-card' : 'broker-raw')
  return <div className={`broker ${className}`}>
    <div className="fc w-full">
      <div className={`broker-logo ${className}__logo`} onClick={()=>onClick()}>
        <div className='broker-logo-img' style={data.image ? {backgroundImage: `url(${APP_URI}/uploads/brokers/${data.image})`}:undefined}/>
      </div>

      <div className="fc w-full">
        {cells.map(cell => (
            <div key={cell.name} className={`${className}__cell broker-cell ${cell.className ? className+cell.className :''}`}>
              <div className={`${className}__cell-name`}>{cell.name}</div>
              {cell.render ? cell.render(data[cell.key]) : <div className={`${className}__value broker-value`}>{String(data[cell.key])}</div>}
            </div>
          )
        )}
      </div>
      
      <div className={`broker-action ${className}__action`}><a href={data.link} target='_blank' className="btn btn-primary-big">открыть счет</a></div>
    </div>
    
  </div>
}


const cellsForex: cellsType<IBrokerForex> = [
  {name: 'Мин. Деп.', key: 'min_dep'},
  {name: 'Кред. плечо', key: 'ratio'},
  {name: 'Спред', key: 'spread'},
  {name: 'Основан', key: 'founded', className: '__cell-abs'},
  {name: 'Сумма вывода', key: 'amount'},
]

const cellsOptions: cellsType<IBrokerOptions> = [
  {name: 'Мин. Деп.', key: 'min_dep'},
  {name: 'Мин. Ставка', key: 'min_bet'},
  {name: 'Бонус', key: 'bonus'},
  {name: 'Регистрация в рф', key: 'reg_rf', className: '__cell-abs', render: (reg_rf) => <div className={reg_rf ? "reg-rf":'reg-rf-x'}/>},
  {name: 'Сумма вывода', key: 'amount'},
]

const cellsCrypto: cellsType<IBrokerCrypto> = [
  {name: 'Мин. Деп.', key: 'min_dep'},
  {name: 'Комиссия', key: 'fee'},
  {name: 'Трафик в сутки', key: 'traffic'},
  {name: 'Кол-во криптовалют', key: 'currencies', className: '__cell-abs'},
  {name: 'Объем торгов', key: 'amount'},
]


export const BrokerOptions = (props: BrokerProps<IBrokerOptions>) => <Broker<IBrokerOptions> {...props} cells={cellsOptions} />
export const BrokerForex = (props: BrokerProps<IBrokerForex>) => <Broker<IBrokerForex> {...props} cells={cellsForex} />
export const BrokerCrypto = (props: BrokerProps<IBrokerCrypto>) => <Broker<IBrokerCrypto> {...props} cells={cellsCrypto} />
