import axios from "axios"
import Loader from "../components/Loader/Loader"
import { useQuery } from "@tanstack/react-query"
import { IStrategy } from "../components/StrategiesList"
import { APP_URI } from "../constants"
import { Link, useParams } from "react-router-dom"
import { useState } from "react"


const useGetArticle = (id: string) => useQuery<{data:{result: IStrategy}}, any>({
  queryKey: ['strategy', id],
  queryFn: ({ signal }) => axios.get(APP_URI+'/strategies/'+id, { signal, params: {json: 1, id} }),
})

function StrategyCard() {
  const {id}= useParams<{id:string}>()
  const {isLoading,error,data} = useGetArticle(id||'0')
  if (isLoading) return <Loader/>

  if (error) return <div>An error has occurred</div>
  if(!data?.data || !data.data.result) {
    return <span>unknown error</span>
  }
  const strat = data.data.result

  const onDownload = () => {
    const n = window.open(strat.file_link, '_blank')
    if(n) n.focus();
  }

  return <div className="bg-white black-text py100">
    <div className="container">
      <div className="df--lg">

        <div className="col12--lg">
          <div className="guide-video-container">
            <div className="guide-video-bg">
              <img src={strat.video} title={strat.description} className="w-full" />
            </div>
          </div>
        </div>
        <div className="col12--lg pl100--lg">
          <h1 className="mb28 uppercase">
            <span className="orange fz20 fz38--lg">стратегия:</span>
            <span className="df fz28 fz48--lg">{strat.name}</span>
          </h1>
          <div className="mb20" dangerouslySetInnerHTML={{ __html: strat.details.replace(/\[([^[\]]*)\]/g, '<span class="orange">$1</span>').replace(/;/g, '<br>') }}>
          </div>
          <button className="download-btn" onClick={onDownload}>Скачать стратегию</button>

        </div>
      </div>
    </div>
  </div>  
}

export default function StrategyPage() {
  const [opened, setOpened] = useState(false)
  const toggleVideo = () => {
    setOpened(p=>!p)
  }
  return <div>
    <StrategyCard/>
    
    <div className="bg3 pt45 pb100">
      <div className="container">
        <h2 className="mb20 fz25 fz35--lg">КАК УСТАНОВИТЬ СТАРТЕГИЮ:</h2>
        <div className="row rg40 aic">
          
          <div className={opened ? "col24" : "col12--lg"}>
            <div className="guide-video-container">
              <div className="guide-video-bg">
                <iframe className='guide-video' src="https://www.youtube.com/embed/RuxG8s9RL9g" title="Инструкция. КАК УСТАНОВИТЬ СТАРТЕГИЮ " frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          </div>

          <div className="col12--lg mla pl20--lg">
            <div className="fz18 fz25--lg color-primary uppercase">Инструкция:</div>
            <ul className="mt5 mb25 fz22--lg">
              <li>В терминале нажать Файл - Откыть каталог данных</li>
              <li>В папку MQL4 - Indicators  извлечь из скачаного архива файлы с расширением .ex4</li>
              <li>В папку Templates извлечь из скачаного архива файлы с расширением .tpl</li>
            </ul>
            <span className="df fz22--lg color-primary uppercase" onClick={toggleVideo}>СМОТРИТЕ ВИДЕО ИНСТРУКЦИЮ</span>
          </div>
          
        </div>
      </div>
    </div>
    
    <div className="bg-white black-text py100">
      <div className="container">
        <div className="free-signals">
          <div className="mra">
            <h2 className="text20">бесплатные сигналы по бинарным опционам</h2>
            <p className="text21">Начните зарабатывать уже сегодня на наших сигналах!</p>
          </div>
          <Link to="/signals" className="btn btn-school">перейти</Link>
        </div>
      </div>
    </div>

  </div>
}