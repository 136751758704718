import React, { useState } from 'react';
import './Accordion.css';

type AccordionProps = {
  title: string;
  content: React.ReactNode;
  children?: never;
} | {
  title: string;
  children: React.ReactNode;
  content?: never;
};

const Accordion: React.FC<AccordionProps> = ({ title, content, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={toggleAccordion}>
        <h3 className="accordion-title">{title}</h3>
        <span className={`accordion-icon ${isOpen ? 'open' : 'closed'}`}></span>
      </div>
      <div className={`accordion-content ${isOpen ? 'open' : ''}`}>
        <div className="accordion-content-inner">{children || content}</div>
      </div>
    </div>
  );
};

export default Accordion;