import { RouteObject, useRoutes } from "react-router-dom"
import Recommendations from "./components/Recomendations"
import IndexPage from "./pages/IndexPage"
import ArticlePage from "./pages/News/ArticlePage"
import BlogLayout from "./pages/News/BlogLayout"
import News from "./pages/News/News"
import School from "./pages/School"
import Signals from "./pages/Signals"
import Strategies from "./pages/Strategies"
import StrategyPage from "./pages/StrategyPage"

const routes: RouteObject[] = [
  {
    path: '/news',
    element: <BlogLayout/>,
    children: [
      { index: true, element: <News/> },
      { path: ':alias', element: <News/>}
    ]
  },
  {
    path: '/blog',
    element: <BlogLayout/>,
    children: [
      {path: ':alias', element: <ArticlePage/>}
    ]
  },
  {
    path: '/strategies', element: <Strategies/>
  },
  {
    path: '/strategies/:id', element: <StrategyPage/>
  },
  {path: '/signals', element: <Signals/>},
  {path: '/school/cryptocurrency', element: <School page='cryptocurrency' />},
  {path: '/school/forex', element: <School page='forex' />},
  {path: '/school/options', element: <School page='options'><Recommendations/></School>},
  {path: '/', element: <IndexPage/>},
  {path: '*', element: <div><h1>не найдена</h1></div>}
]

const AppRouter = () => {
  return useRoutes(routes)
}

export default AppRouter
    // <div className="container pt50" style={{minHeight: '80vh'}}>
    //   <h2>На данный момент раздел "Стратегии" находятся в разработке. При этом стратегии выдаются нашим партнерам в любой момент по запросу.</h2>
    //   <h3>Для получения стратегий по торговле бинарными опционами обращайтесь в тех.поддержку в онлайн-чат либо в <a href="https://t.me/fxleader_support" target='_blank' className="link-verify">SUPPORT Telegram</a></h3>
    // </div>