import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useParams } from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import { APP_URI } from "../../constants"
import { Article, INews } from "./BlogLayout"
import "./news.css"

const useGetTopNews = () => useQuery<{data:{result: {items: INews[], total: number}}}, any>({
  queryKey: ['popular'],
  queryFn: ({ signal }) => axios.get(APP_URI+'/news/popular', { signal, params: {json: 1} }),
})


const useGetArticle = (alias: string) => useQuery<{data:{result: INews}}, any>({
  queryKey: ['article', alias],
  queryFn: ({ signal }) => axios.get(APP_URI+'/blog/'+alias, { signal, params: {json: 1, alias} }),
})

export const MoreNews = () => {
  const { isLoading, error, data } = useGetTopNews()
  if (isLoading) return <Loader/>
  if (error || !data?.data) return <div>An error has occurred</div>
  return (
    
    <div className="other__news">
      <h4 className="other__news-title">🔥Топ 3 самые просматриваемые новости</h4>
      
      <div className="news-cards pb50">
        <div className="row">
          {data?.data.result.items.map(art => <div key={art.alias} className="px10 col8--lg"><Article {...art} /></div>)}
        </div>
      </div>


      {/* <div className="row">
        <div className="col8--lg">
          <div className="news-card__header" style={{backgroundImage: "url(/man-with-banknotes2.png);"}}>
          <div className="other__news-card__title">Где взять деньги </div>
          </div>
          <div className="news-card__inner">
            <div className="df aic">
              <div className="topic">криптовалюта| заработок в интернете</div>
              <div className="df aic"> 
                <div className="eye"></div>
                <span className="number__views">204</span>
              </div>
            </div>
            <div className="news-theme">Рассказали, как и где выгодно купить криптовалюту! </div>
          </div>
        </div>
        <div className="col8--lg">
          <div className="news-card__header" style={{backgroundImage: "url(/man-with-banknotes2.png);"}}>
          <div className="other__news-card__title">Где взять деньги </div>
          </div>
          <div className="news-card__inner">
            <div className="df aic">
              <div className="topic">криптовалюта| заработок в интернете</div>
              <div className="df aic"> 
                <div className="eye"></div>
                <span className="number__views">204</span>
              </div>
            </div>
            <div className="news-theme">Рассказали, как и где выгодно купить криптовалюту! </div>
          </div>
        </div>
        <div className="col8--lg">
          <div className="news-card__header" style={{backgroundImage: "url(/man-with-banknotes2.png);"}}>
          <div className="other__news-card__title">Где взять деньги </div>
          </div>
          <div className="news-card__inner">
            <div className="df aic">
              <div className="topic">криптовалюта| заработок в интернете</div>
              <div className="df aic"> 
                <div className="eye"></div>
                <span className="number__views">204</span>
              </div>
            </div>
            <div className="news-theme">Рассказали, как и где выгодно купить криптовалюту! </div>
          </div>
        </div>
      </div> */}


    </div>
  )
}

export default function ArticlePage() {
  const params = useParams<{alias:string}>()

  const { isLoading, error, data } = useGetArticle(params.alias||'')

  if (isLoading) return <Loader/>

  if (error || !data?.data) return <div>An error has occurred</div>
  const article = data.data.result

  return (
    // <div className="cryptocurrency-bread__crumbs"><a className="cryptocurrency-bread__crumbs-item" href="#">главная </a> <a className="cryptocurrency-bread__crumbs-item" href="#">> новостной портал </a><a className="cryptocurrency-bread__crumbs-item" href="#">> Криптовалюта </a><span className="cryptocurrency-bread__crumbs-item">> как делать деньги в крипте</span></div>
    <>
    <div className="bg-white b-top-r20">
      <div className="col24--lg">
        <div>
          <div className="cryptocurrency-card__header" style={{backgroundImage: `url(${APP_URI}/uploads/news/${article.image})`}}>
            <div className="cryptocurrency-card__title">{article.title}</div>
          </div>
          <div className="news-card__inner">
            <div className="df aic">
              <div className="topic">криптовалюта| заработок в интернете</div>
              <div className="df aic"> 
                <div className="eye"></div>
                <span className="number__views">204</span>
              </div>
            </div>
            <div className="cryptocurrency-theme">{article.description}</div>
          </div>
        </div>
      </div>
      <main className="container" dangerouslySetInnerHTML={{ __html: article.content }} />
      {/* <div className="cryptocurrency-earning">
        <div>Вот несколько способов заработка денег в криптовалюте:</div>
        <ol>
          <li>Инвестирование в криптовалюты: Покупка криптовалют и держание ее на длительный период времени может привести к значительному росту капитала, если криптовалюта повысит свою цену. Однако, инвестирование в криптовалюты сопряжено со значительными рисками, и перед тем, как принять решение, необходимо тщательно изучить все возможные риски.</li>
          <li>Трейдинг: Трейдинг криптовалютами предполагает покупку и продажу криптовалют в течение короткого периода времени с целью извлечения прибыли от изменения цены. Для трейдинга вам потребуется изучить анализ технических графиков, понимание рыночных трендов и способность принимать быстрые решения.</li>
          <li>Майнинг: Майнинг - это процесс создания новых блоков в блокчейне и получение вознаграждения за это. Для майнинга вам нужно купить специализированное оборудование, настроить его и затем присоединиться к группе майнеров.</li>
          <li>Участие в ICO и IEO: ICO (Initial Coin Offering) и IEO (Initial Exchange Offering) - это способы финансирования новых проектов, связанных с криптовалютами. Вы можете купить токены, выпущенные в ходе ICO или IEO, и надеяться, что проект будет успешным и токены повысят свою цену.</li>
          <li>Стейкинг: Стейкинг - это процесс удержания криптовалюты на вашем кошельке и получение вознаграждения за это. Некоторые криптовалюты используют стейкинг в качестве механизма подтверждения транзакций в блокчейне.</li>
        </ol>
        <div>Не забывайте, что криптовалюты имеют высокую степень риска, и прежде чем начать инвестировать, вы должны тщательно изучить все возможные риски и принять осознанное решение.</div>
        <div className="man-with-banknotes"> </div>
      </div>	 */}
    </div>
    
  </>
  )
}