import Config from 'i18next'
// import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export const supportedLocales = ['en', 'ua'];
const normalizedPath = window.location.pathname.toLowerCase();

export const detectedLocale = normalizedPath === '/' ? undefined : supportedLocales.find(locale => normalizedPath.startsWith(`/${locale}/`) || normalizedPath === `/${locale}`);

const i18n = Config
// .use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
  lng: detectedLocale,
  resources: {
    en: {
      // errors: require('./translations/en/errors.json'),
      // landing: require('./translations/en/landing.json'),
      // translation: require('./translations/en/common.json'),
    },
    ru: {
      // errors: require('./translations/ru/errors.json'),
      // landing: require('./translations/ru/landing.json'),
      // translation: require('./translations/ru/common.json'),
    },

  },

  fallbackLng: "ru", // 'ru',
  debug: true,
  detection: {
    order: ['queryString', 'cookie'],
    caches: ['cookie']
  },
  // ns: 'v003',
  interpolation: {
    escapeValue: false
  }
})
export default i18n