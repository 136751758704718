import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { APP_URI } from "../../constants";
import Loader from "../Loader/Loader";
import Modal from "../modal/Modal";
import { useModal } from "../modal/useModal";
import Recommendations from "../Recomendations";
import { BrokerCrypto, BrokerForex, BrokerOptions, IBroker, IBrokerCrypto, IBrokerForex, IBrokerOptions } from "./Broker";



const ForexBrokers = ({setSelected}: {setSelected:(broker: IBroker|null)=>void}) => {
  const type = 'forex'
  
  const { isLoading, error, data } = useQuery<{data:{result: IBrokerForex[]}}>({
    queryKey: ['brokers', type],
    queryFn: ({ signal }) => axios.get(APP_URI+'/brokers/', { signal, params: {json: 1, type} }),
  })

  if (isLoading) return <Loader/>

  if (error) return <div>An error has occurred</div>
  console.log(data)
  if(!data?.data || !data.data.result) {
    return <span>unknown error</span>
  }
  const brokers = data.data.result || []

  const pocket = brokers.find(broker => broker.id == 7)

  return (
    <>
    <div className="container">
        
      <div className="table-head">
        <div className="broker-col broker-col--logo">БРОКЕРЫ</div>
        <div className="fc w-full">
          <div className="broker-col">МИНИМАЛЬНЫЙ ДЕПОЗИТ</div>
          <div className="broker-col">Кред. плечо</div>
          <div className="broker-col">Спред</div>
          <div className="broker-col">ОСНОВАН</div>
          <div className="broker-col">СУММА ВЫВОДА</div>
        </div>
        <div className="broker-col broker-col--action">САЙТ БРОКЕРА</div>
      </div>
      
      <div className="brokers">
      {pocket ? <BrokerForex key={pocket.id} onClick={()=>setSelected(pocket)} data={pocket} /> : null}
      {brokers.length ? brokers.filter(br => br.id != 7).map(broker => <BrokerForex key={broker.id} onClick={()=>setSelected(broker)} data={broker} />) : <h2>Скоро добавим!</h2>}
      </div>

    </div>

    </>
  )
}

const CryptoBrokers = ({setSelected}: {setSelected:(broker: IBroker|null)=>void}) => {
  const type = 'crypto'
  
  const { isLoading, error, data } = useQuery<{data:{result: IBrokerCrypto[]}}>({
    queryKey: ['brokers', type],
    queryFn: ({ signal }) => axios.get(APP_URI+'/brokers/', { signal, params: {json: 1, type} }),
  })

  if (isLoading) return <Loader/>

  if (error) return <div>An error has occurred</div>
  console.log(data)
  if(!data?.data || !data.data.result) {
    return <span>unknown error</span>
  }
  const brokers = data.data.result || []

  return (
    <>
    <div className="container">
        
      <div className="table-head">
        <div className="broker-col broker-col--logo">БРОКЕРЫ</div>
        <div className="fc w-full">
          <div className="broker-col">МИНИМАЛЬНЫЙ ДЕПОЗИТ</div>
          <div className="broker-col">Комиссия</div>
          <div className="broker-col">Трафик в сутки</div>
          <div className="broker-col">Кол-во криптовалют</div>
          <div className="broker-col">Объем торгов</div>
        </div>
        <div className="broker-col broker-col--action">САЙТ БРОКЕРА</div>
      </div>
      
      <div className="brokers">
      {brokers.length ? brokers.map(broker => <BrokerCrypto key={broker.id} onClick={()=>setSelected(broker)} data={broker} />) : <h2>Скоро добавим!</h2>}
      </div>

    </div>

    </>
  )
}


const OptionBrokers = ({setSelected}: {setSelected:(broker: IBroker|null)=>void}) => {
  const type = 'options'
  
  const { isLoading, error, data } = useQuery<{data:{result: IBrokerOptions[]}}>({
    queryKey: ['brokers', type],
    queryFn: ({ signal }) => axios.get(APP_URI+'/brokers/', { signal, params: {json: 1, type} }),
  })

  if (isLoading) return <Loader/>

  if (error) return <div>An error has occurred</div>
  console.log(data)
  if(!data?.data || !data.data.result) {
    return <span>unknown error</span>
  }
  const brokers = data.data.result || []

  return (
    <>
    <div className="container">
        
      <div className="table-head">
        <div className="broker-col broker-col--logo">БРОКЕРЫ</div>
        <div className="fc w-full">
          <div className="broker-col">МИНИМАЛЬНЫЙ ДЕПОЗИТ</div>
          <div className="broker-col">МИНИМАЛЬНАЯ СТАВКА</div>
          <div className="broker-col">БОНУС</div>
          <div className="broker-col">РЕГИСТРАЦИЯ В РФ</div>
          <div className="broker-col">СУММА ВЫВОДА</div>
        </div>
        <div className="broker-col broker-col--action">САЙТ БРОКЕРА</div>
      </div>
      
      <div className="brokers">
      {brokers.length ? brokers.map(broker => <BrokerOptions key={broker.id} onClick={()=>setSelected(broker)} data={broker} />) : <h2>Скоро добавим!</h2>}
      </div>

    </div>

    </>
  )
}





const ModalContent = ({data}: {data: IBroker}) => {
  
  // const { isLoading, error, data } = useQuery<{data:{result: {id: any, name: string, content: string}}}>({
  //   queryKey: ['broker', id],
  //   queryFn: ({ signal }) => axios.get(APP_URI+'/brokers/'+id, { signal, params: {json: 1} }),
  // })

  // if (isLoading) return <Loader/>

  // if (error) return <div>An error has occurred</div>
  // console.log(data)
  // if(!data?.data || !data.data.result) {
  //   return <span>unknown error</span>
  // }
  return (
    <div className="school-content">
      <h2 className="school-content__title">О БРОКЕРЕ: {data.name}</h2>
      <p>{data.description}</p>
    </div>
  )
}


function How() {
	const {close,isOpen,open} = useModal()

  return (
		<section className="section bg2">
      <div className="container">

        <div className="text15-block">
          <div className="line3">
            <div className='how-chose'> 
              <h2 className="title2 mb30">Как выбрать лучшего брокера</h2>
              <p className="text-mid fw5">Торговля бинарными опционами - популярная форма финансовых инвестиций, предлагающая потенциал для получения значительной прибыли.</p>
              <p className="text-mid fw5">Однако выбор правильного брокера имеет решающее значение для успеха на этом рынке. Вот несколько ключевых факторов, которые следует учитывать при выборе лучшего брокера бинарных опционов.</p>
            </div>
            <div className="ma">
              <button className="btn btn-primary-big" onClick={open}>смотреть</button>
              <Modal isOpen={isOpen} close={close}>
                <div className="school-content">
                  <h2 className="school-content__title">КАК ВЫБРАТЬ ЛУЧШЕГО БРОКЕРА</h2>
                  <p>{`Технология блокчейн в последние годы вызывает ажиотаж и широко обсуждается как революционная концепция, которая потенциально может разрушить различные отрасли. 
По своей сути блокчейн - это децентрализованный цифровой реестр, который регистрирует транзакции через сеть компьютеров безопасным, защищенным от 
несанкционированного доступа и прозрачным способом.

Децентрализация является важнейшим аспектом технологии блокчейн. В отличие от традиционных систем, где данные хранятся в центральном месте и управляются 
одним лицом, децентрализованные системы построены на распределенной сети узлов, где каждый узел имеет копию книги учета и участвует в проверке транзакций. 
Это создает безопасную, не вызывающую доверия и распределенную сеть, где транзакции проверяются с помощью алгоритмов консенсуса, вместо того, чтобы полагаться 
на центральный орган.

Одним из наиболее известных применений технологии блокчейн являются криптовалюты, такие как биткоин. Однако потенциальные варианты использования технологии 
блокчейн выходят далеко за рамки цифровых валют. Децентрализованные системы могут использоваться для создания прозрачных, безопасных и защищенных от несанкционированного доступа приложений, таких как управление цепочками поставок, системы голосования и управление цифровой идентификацией.

Блокчейны безопасны и прозрачны, поскольку они используют криптографические методы для проверки транзакций и создания неизменяемого реестра данных. 
Как только блок данных добавляется в блокчейн, он не может быть изменен или удален, что делает его идеальной платформой для записи и отслеживания транзакций. 
Децентрализованный характер блокчейна гарантирует, что данные хранятся на нескольких узлах, что делает их практически невозможными для взлома или 
несанкционированного доступа.

В заключение, блокчейн и децентрализованные системы разрушают традиционные централизованные системы и готовы сыграть значительную роль в формировании 
будущего различных отраслей. Потенциальные преимущества этих систем, такие как безопасность, прозрачность и эффективность, делают их технологией, 
заслуживающей изучения и инвестирования. Поскольку мир становится все более взаимосвязанным, децентрализованные системы и технология блокчейн обладают 
потенциалом для предоставления новых решений проблем, с которыми сталкиваются традиционные централизованные системы.`}</p>
                </div>
              
              </Modal>
            </div>
            
        </div>


        </div>

      </div>
    </section>
  )
}



export default function BrokersList() {
  const [currentTab, setCurrentTab] = useState(1)
  const [selected, setSelected] = useState<IBroker|null>(null)

  return (
    <>
		<div className="container">
      
      <div className="tac mb50">
        <h2 className="title2">выберите брокера</h2>
      </div>

      <div className="choice">
        <button onClick={()=>setCurrentTab(1)} className={1===currentTab ? "choice-tab active" : 'choice-tab'}>
          <span>Бинарные опционы</span>
        </button>
        <button onClick={()=>setCurrentTab(2)} className={2===currentTab ? "choice-tab active" : 'choice-tab'}>Форекс</button>
        <button onClick={()=>setCurrentTab(3)} className={3===currentTab ? "choice-tab active" : 'choice-tab'}>Криптовалют</button>
      </div>

    </div>
    {currentTab === 1 ? <OptionBrokers setSelected={setSelected}/> : null}
    {currentTab === 2 ? <ForexBrokers setSelected={setSelected}/> : null}
    {currentTab === 3 ? <CryptoBrokers setSelected={setSelected}/> : null}

    <div className="container">
      <Recommendations/>
    </div>

    <Modal isOpen={selected!==null} close={()=>setSelected(null)}>
      {selected ? <ModalContent data={selected}/> : null}
    </Modal>
    <How/>
    </>
  )
}