import logoBlack from '../../logo-black.svg';
import './banner.css'
export default function Banner() {
  return (
    <div className="banner2">
      <div className="container">
        <div className="banner2-inner">

          <div className="banner2-content">
            <div className="banner2-logo">
              <img className='banner2-logo-img' src={logoBlack} alt="FXLEADER" />
            </div>
            <div className="banner2-text">следите за нами в&nbsp;телеграм</div>
          </div>
          <a className="banner2-link" href="https://t.me/fxleader_net" target='_blank'><span className="relative">перейти</span></a>

        </div>
      </div>
      
    </div>
  )
}