import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { ReactNode, useState } from "react"
import Modal from "../components/modal/Modal"
import { APP_URI } from "../constants"
import { Link } from 'react-router-dom';
import './school.css'
import Loader from "../components/Loader/Loader"

interface ISchoolItem {
  title: string
  id: number
  content: string
}
const SchoolItem = ({data, open}: {data: ISchoolItem, open: () => void }) => {
  return (
    <div className="partition" onClick={open}>
      <div className="text17">{data.title}</div>
      <div className="more"></div>
    </div>
  )
}


export default function School({page, children}:{page: string, children?: ReactNode}) {

  const [modalContent, setModalContent] = useState<ISchoolItem>()
  
  const { isLoading, error, data } = useQuery<{data:{result: {items: ISchoolItem[], title: string}}}>({
    queryKey: ['school', page],
    queryFn: ({ signal }) => axios.get(APP_URI+'/school/'+page, { signal, params: {json: 1} }),
  })

  if (isLoading) return <Loader/>

  if (error) return <div>An error has occurred</div>
  console.log(data)
  if(!data?.data || !data.data.result) {
    return <span>unknown error</span>
  }

  return (
    <div className="main">
      <div className="school-container">
        <h1 className="school-title">{data.data.result.title}</h1>

        <div className="md--breadcrumbs">
          <Link to='/' className="to-main">Главная</Link>
          <span> / {data.data.result.title}</span>
        </div>

        <div className="items">
          {data.data.result.items.map(item => <SchoolItem open={()=>setModalContent(item)} key={item.title} data={item} />)}
        </div>

        {children}
      </div>
      <Modal isOpen={modalContent!==undefined} close={()=>setModalContent(undefined)}>
        {modalContent ? <div className="school-content">
          <h2 className="school-content__title">{modalContent.title}</h2>
          <p>{modalContent.content}</p>
          <div className="free-signals">
            <div className="mra">
              <h2 className="text20">бесплатные сигналы по бинарным опционам</h2>
              <p className="text21">Начните зарабатывать уже сегодня на наших сигналах!</p>
            </div>
            <Link to="/signals" className="btn btn-school">перейти</Link>
          </div>
        </div> : <div className="school-content">empty</div>}
          
      </Modal>
    </div>
  )
}