import Banner from "../components/Banner";
import logoWhite from "../logo-white.svg";

export default function MainPage() {
  return (
    <div>
      <Banner/>
      <div className="container">
        
        <div className="home">
          <div className="home__logo">
            <img className='fx-logo' src={logoWhite} alt="FXLEADER" />
          </div>
          <div className="home__content">
            <h1 className="title1">Рейтинг брокеров 2023&nbsp;года</h1>
            <h4 className="text10">Форекс и бинарные опционы</h4>
            <p className="text-mid">
              Мы отобрали для вас самые лучшие предложение на этих рынках!
              Следуйте инструкциям и зарабатывайте деньги!
            </p>
          </div>
        </div>
      </div>



    </div>
  );
}
