import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import './styles/grid.css';
import './gstyles.css';
import { detectedLocale as locale } from './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60000
    },
  },
})

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter basename={locale?'/'+locale:undefined}>
      <App />
    </BrowserRouter>
  </QueryClientProvider>
);