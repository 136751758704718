import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from '../../logo.svg';
import './header.css';
import LangBtn from '../LangBtn/LangBtn';

const Nav = () => (
  <div className="header__nav">
    <div className="dropdown-nav nav-item">
      <span>Обучение</span>
      <div className="dropdown">
        <div className="dropdown-container">
          <NavLink className='dropdown__nav-item' to='/school/options'>Школа Бинарных Опционов</NavLink>
          <NavLink className='dropdown__nav-item' to='/school/cryptocurrency'>Школа Криптовалют</NavLink>
          <NavLink className='dropdown__nav-item' to='/school/forex'>Школа Форекс</NavLink>
        </div>
      </div>
    </div>
    <NavLink className='nav-item' to='signals'>Сигналы</NavLink>
    <NavLink className='nav-item' to='news'>Новости</NavLink>
    <NavLink className='nav-item' to='strategies'>Стратегии</NavLink>
  </div>
)
export default function Header () {
  const isDesktop = window.innerWidth > 768
  const loc = useLocation()
  return (
    <>
      <header className="header">
        <div className="header-container">
          <Link className="header__logo" to='/'><img src={logo} className="header__logo" alt="logo" /></Link>
          {loc.pathname !== '/' ? <Link className="header__main" to='/'>Главная</Link> : null}
          {isDesktop?<Nav/>:null}
          <a href='https://t.me/fxleader_net' target='_blank' className="btn btn-primary header__btn">Telegram канал</a>
          {/* <div className="ml12"><LangBtn /></div> */}
        </div>
      </header>
      {isDesktop?null:<Nav/>}
    </>
  )
}