import { useQuery } from "@tanstack/react-query"
import { APP_URI } from "../constants"
import axios from "axios"
import Loader from "./Loader/Loader"
import { Link } from "react-router-dom"
import './strategies.css'

export interface IStrategy {
  id: number
  name:string
  description:string
  details:string
  video:string
  file_link:string
}

const StrategyCard = ({str}: {str:IStrategy}) => {
  return (
    <div className="strategy-card">
      <div className="fz25 fw6 mb12">{str.name}</div>
      <img className="mb25 w-full" src={str.video} alt={str.description} />
      <div className="df aic jcb">
        <div className="str-num">№{str.id}</div>
        <Link to={`/strategies/${str.id}`} className="strategy-card__btn">Смотреть</Link>
      </div>
      
    </div>
  )
}

export default function StrategiesList() {
  
  const { isLoading, error, data } = useQuery<{data:{result: {items: IStrategy[]}}}>({
    queryKey: ['strategies'],
    queryFn: ({ signal }) => axios.get(APP_URI+'/strategies/', { signal, params: {json: 1} }),
  })

  if (isLoading) return <Loader/>

  if (error) return <div>An error has occurred</div>
  if(!data?.data || !data.data.result) {
    return <span>unknown error</span>
  }
  const strategies = data.data.result.items || []


  return (
    <div className="bg-white black-text pt50 pb50">
      <div className="container">
        <div className="row aic rg30 mb40">
          <div className="col12--lg px10"><img src="/strategies.jpg" className="w-full" alt="logo" /></div>
          <div className="col12--lg px10">
            <h1 className="fz20 fz35--lg fz48--xl mb20 uppercase">СТРАТЕГИИ бинарных опционов</h1>
            <div className="fw5 fz25--lg">Получайте высокие доходы с нашими проверенными методиками</div>
          </div>
        </div>

        <div className="px10">
                <p className="fz25">Выберите свою комфортную стратегию для торговли!</p>
                <p className="fz14 grey-text mb60">Так как, комфортность определяется за счет тестирования этих стратегий, следите за своими финансами и не допускайте ошибок в РИСК-МЕНЕДЖМЕНТЕ</p>
                  
        </div>


        {strategies.length ? 
        <div className="row rg30">{
          strategies.map(str => <div className="col8--lg rg20 px10" key={str.id}>
            <StrategyCard str={str}/>
          </div>)}
        </div>
        : <h2>Скоро добавим!</h2>}


<div className="py100">
        <div className="free-signals">
          <div className="mra">
            <h2 className="text20">бесплатные сигналы по бинарным опционам</h2>
            <p className="text21">Начните зарабатывать уже сегодня на наших сигналах!</p>
          </div>
          <Link to="/signals" className="btn btn-school">перейти</Link>
        </div>    
</div>
      
      </div>

    </div>
  )
}