import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import { APP_URI } from "../../constants"
import { Article, INews } from "./BlogLayout"
import "./news.css"

const useGetNews = (page: number, alias?: string) => useQuery<{data:{result: {items: INews[], total: number}}}, any>({
  queryKey: ['news', alias, page],
  queryFn: ({ signal }) => axios.get(APP_URI+'/news/'+(alias??''), { signal, params: {json: 1, page} }),
})


const useGetRecommendNews = () => useQuery<{data:{result: {items: INews[], total: number}}}, any>({
  queryKey: ['recommend'],
  queryFn: ({ signal }) => axios.get(APP_URI+'/news/recommend', { signal, params: {json: 1} }),
})



const RecommendNews = () => {
  const { isLoading, error, data } = useGetRecommendNews()
  if (isLoading) return <Loader/>
  if (error || !data?.data) return <div>An error has occurred</div>

  return (
    <div className="popular-news">
      <h2 className="popular-news-title"><span style={{color: '#FF7A00'}}>FXLEADER</span> рекомендует к прочтению</h2>
      <div>
        {
          data?.data.result.items.map(art => <div key={art.alias} className="popular-news-inner">
            <div>
              <div className="news-category">{art.title}</div>
              <div className="popular-news-theme">{art.description}</div>
            </div>
            <Link to={'/blog/'+art.alias} className="popular-news-link__see">смотреть</Link>
        </div>
        )}
      </div>
    </div>
  )
}



export default function News() {
  const params = useParams<{alias: string}>()
  const [page, setPage] = useState(1)
  // const limit = 6

  const { isLoading, error, data } = useGetNews(page, params.alias)

  if (isLoading) return <Loader/>

  if (error || !data?.data) return <div>An error has occurred</div>

  return (
    <div className="fresh-news">
      <h2 className="tac fresh-news-title">новости</h2>
      <div className="news-cards pb50">
        <div className="row">
          {data?.data.result.items.map(art => <div key={art.alias} className="col12--lg px10"><Article {...art} /></div>)}
        </div>
        {/* {(data?.data.result.total > page * limit) ? <button className="show__more-link" onClick={()=>setPage(p=>p+1)}>Показать еще...</button> : null}               */}
      </div>
      <RecommendNews/>
    </div>
  )
}