import { Link } from 'react-router-dom';
import BrokersList from '../components/broker/BrokersList';
import './brokers.css';
import MainPage from './MainPage';
// import logo from '../logo.svg';
// {/* <img className='fx-leader-logo' src={logo} alt="FXLEADER" /> */}

export default function IndexPage() {
  return <div className="main" id='home-page'>

		<MainPage/>
		<BrokersList/>

			<section className='section bg-white'>
				<div className='container jcc'>
					<div>
						<h2 className="title2 mb30">БЕСПЛАТНЫЕ СТРАТЕГИИ ОТ КОМАНДЫ <span className="fw7">FX&nbsp;LEADER</span></h2>

						<div className="strategies__best-wrapper">
							<div className="strategies__best">Найдите лучшую стратегию под себя и делайте деньги вместе с нами!</div>
							<Link to="/strategies" className="btn btn-primary-big">ПЕРЕЙТИ</Link>
						</div>
					</div>

				</div>				
			</section>


			<div className="container py20">
				<div className="row jcc">
					<div className="col10--md">
						<Link to='/news' className='btn3'>НОВОСТНОЙ ПОРТАЛ</Link>
					</div>
					<div className="col10--md">
						<Link to='/signals' className='btn3'>БЕСПЛАТНЫЕ СИГНАЛЫ</Link>
					</div>
				</div>
			</div>

  </div>
}
